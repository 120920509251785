/**
 * actions.ts
 * List of Redux action types
 */

export enum AuthActionTypeEnum {
  LOG_IN_REQUEST = 'LOG_IN_REQUEST',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAIL = 'LOG_IN_FAIL',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
}

export enum SnackBarActionTypeEnum {
  UPDATE = 'SNACK_BAR_UPDATE',
  RESET = 'SNACK_BAR_RESET',
}

export enum DoorConfigActionTypeEnum {
  CREATE_REQUEST = 'DOOR_CONFIG_CREATE_REQUEST',
  CREATE_SUCCESS = 'DOOR_CONFIG_CREATE_SUCCESS',
  CREATE_FAIL = 'DOOR_CONFIG_CREATE_FAIL',
  READ_REQUEST = 'DOOR_CONFIG_READ_REQUEST',
  READ_SUCCESS = 'DOOR_CONFIG_READ_SUCCESS',
  READ_FAIL = 'DOOR_CONFIG_READ_FAIL',
  UPDATE_REQUEST = 'DOOR_CONFIG_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'DOOR_CONFIG_UPDATE_SUCCESS',
  UPDATE_FAIL = 'DOOR_CONFIG_UPDATE_FAIL',
  DELETE_REQUEST = 'DOOR_CONFIG_DELETE_REQUEST',
  DELETE_SUCCESS = 'DOOR_CONFIG_DELETE_SUCCESS',
  DELETE_FAIL = 'DOOR_CONFIG_DELETE_FAIL',
  READ_LIST_REQUEST = 'DOOR_CONFIG_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'DOOR_CONFIG_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'DOOR_CONFIG_READ_LIST_FAIL',
  RESET_API_STATE = 'DOOR_CONFIG_RESET_API_STATE',
}

export enum SupplierActionTypeEnum {
  CREATE_REQUEST = 'SUPPLIER_CREATE_REQUEST',
  CREATE_SUCCESS = 'SUPPLIER_CREATE_SUCCESS',
  CREATE_FAIL = 'SUPPLIER_CREATE_FAIL',
  READ_REQUEST = 'SUPPLIER_READ_REQUEST',
  READ_SUCCESS = 'SUPPLIER_READ_SUCCESS',
  READ_FAIL = 'SUPPLIER_READ_FAIL',
  UPDATE_REQUEST = 'SUPPLIER_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'SUPPLIER_UPDATE_SUCCESS',
  UPDATE_FAIL = 'SUPPLIER_UPDATE_FAIL',
  DELETE_REQUEST = 'SUPPLIER_DELETE_REQUEST',
  DELETE_SUCCESS = 'SUPPLIER_DELETE_SUCCESS',
  DELETE_FAIL = 'SUPPLIER_DELETE_FAIL',
  READ_LIST_REQUEST = 'SUPPLIER_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'SUPPLIER_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'SUPPLIER_READ_LIST_FAIL',
  RESET_API_STATE = 'SUPPLIER_RESET_API_STATE',
}

export enum TagActionTypeEnum {
  CREATE_REQUEST = 'TAG_CREATE_REQUEST',
  CREATE_SUCCESS = 'TAG_CREATE_SUCCESS',
  CREATE_FAIL = 'TAG_CREATE_FAIL',
  READ_REQUEST = 'TAG_READ_REQUEST',
  READ_SUCCESS = 'TAG_READ_SUCCESS',
  READ_FAIL = 'TAG_READ_FAIL',
  UPDATE_REQUEST = 'TAG_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS',
  UPDATE_FAIL = 'TAG_UPDATE_FAIL',
  DELETE_REQUEST = 'TAG_DELETE_REQUEST',
  DELETE_SUCCESS = 'TAG_DELETE_SUCCESS',
  DELETE_FAIL = 'TAG_DELETE_FAIL',
  READ_LIST_REQUEST = 'TAG_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'TAG_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'TAG_READ_LIST_FAIL',
  RESET_API_STATE = 'TAG_RESET_API_STATE',
}

export enum StockRecActionTypeEnum {
  CREATE_SUCCESS = 'STOCK_RECON_CREATE_SUCCESS',
  READ_SUCCESS = 'STOCK_RECON_READ_SUCCESS',
  UPDATE_SUCCESS = 'STOCK_RECON_UPDATE_SUCCESS',
  DELETE_SUCCESS = 'STOCK_RECONC_DELETE_SUCCESS',
  READ_LIST_SUCCESS = 'STOCK_RECON_READ_LIST_SUCCESS',
  READ_LIST_DETAIL_SUCCESS = 'STOCK_RECON_READ_LIST_DETAIL_SUCCESS',
  RESET_API_STATE = 'STOCK_RECON_RESET_API_STATE',
}

export enum ShipOwnerActionTypeEnum {
  SHIP_OWNER_CREATE_SUCCESS = 'SHIP_OWNER_CREATE_SUCCESS',
  SHIP_OWNER_READ_SUCCESS = 'SHIP_OWNER_READ_SUCCESS',
  SHIP_OWNER_UPDATE_SUCCESS = 'SHIP_OWNER_UPDATE_SUCCESS',
  SHIP_OWNER_DELETE_SUCCESS = 'SHIP_OWNER_DELETE_SUCCESS',
  SHIP_OWNER_READ_LIST_SUCCESS = 'SHIP_OWNER_READ_LIST_SUCCESS',
  SHIP_OWNER_READ_VESSEL_CLASSES_SUCCESS = 'SHIP_OWNER_READ_VESSEL_CLASSES_SUCCESS',
  SHIP_OWNER_RESET_API_STATE = 'SHIP_OWNER_RESET_API_STATE',
  SHIP_OWNER_DETAIL_RESET = 'SHIP_OWNER_DETAIL_RESET',
  SHIP_OWNER_READ_DETAIL_SUCCESS = 'SHIP_OWNER_READ_DETAIL_SUCCESS',
  SHIP_OWNER_READ_ERP_ACCOUNT_SUCCESS = 'SHIP_OWNER_READ_ERP_ACCOUNT_SUCCESS',
  SHIP_OWNER_READ_ERP_SEVER_SUCCESS = 'SHIP_OWNER_READ_ERP_SERVER_SUCCESS',
  SHIP_OWNER_CREATE_ERP_ACCOUNT_SUCCESS = 'SHIP_OWNER__CREATE_ERP_ACCOUNT_SUCCESS',
  SHIP_OWNER_UPDATE_ERP_ACCOUNT_SUCCESS = 'SHIP_OWNER_UPDATE_ERP_ACCOUNT_SUCCESS',
  SHIP_OWNER_CREATE_ERP_SERVER_SUCCESS = 'SHIP_OWNER_CREATE_ERP_SERVER_SUCCESS',
  SHIP_OWNER_UPDATE_ERP_SERVER_SUCCESS = 'SHIP_OWNER_UPDATE_ERP_SERVER_SUCCESS',
  SHIP_OWNER_CREATE_CONFIGURATION_SUCCESS = 'SHIP_OWNER_CREATE_CONFIGURATION_SUCCESS',
  SHIP_OWNER_UPDATE_CONFIGURATION_SUCCESS = 'SHIP_OWNER_UPDATE_CONFIGURATION_SUCCESS',
  SHIP_OWNER_READ_CONFIGURATION_SUCCESS = 'SHIP_OWNER_READ_CONFIGURATION_SUCCESS',
}

export enum VesselActionTypeEnum {
  SHIP_OWNER_READ_VESSELS = 'SHIP_OWNER_READ_VESSELS',
  GET_TRANSFORMATION_INFO = 'GET_TRANFORMATION_INFO',
  GET_TRANSFORMATION_DONE = 'GET_TRANSFORMATION_DONE',
  SHIP_OWNER_READ_VESSELS_SUCCESS = 'SHIP_OWNER_READ_VESSELS_SUCCESS',
  CREATE_SUCCESS = 'VESSEL_CREATE_SUCCESS',
  READ_SUCCESS = 'VESSEL_READ_SUCCESS',
  UPDATE_SUCCESS = 'VESSEL_UPDATE_SUCCESS',
  DELETE_SUCCESS = 'VESSEL_DELETE_SUCCESS',
  READ_LIST_SUCCESS = 'VESSEL_READ_LIST_SUCCESS',
  RESET_API_STATE = 'VESSEL_RESET_API_STATE',
  READ_LIST_CONFIG_VESSEL_SETTING_SUCCESS = 'READ_LIST_CONFIG_VESSEL_SETTING_SUCCESS',
}

export enum VesselClassActionTypeEnum {
  CREATE_REQUEST = 'VESSEL_CLASS_CREATE_REQUEST',
  CREATE_SUCCESS = 'VESSEL_CLASS_CREATE_SUCCESS',
  CREATE_FAIL = 'VESSEL_CLASS_CREATE_FAIL',
  DELETE_REQUEST = 'VESSEL_CLASS_DELETE_REQUEST',
  DELETE_SUCCESS = 'VESSEL_CLASS_DELETE_SUCCESS',
  DELETE_FAIL = 'VESSEL_CLASS_DELETE_FAIL',
  READ_LIST_REQUEST = 'VESSEL_CLASS_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'VESSEL_CLASS_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'VESSEL_CLASS_READ_LIST_FAIL',
  RESET_API_STATE = 'VESSEL_CLASS_RESET_API_STATE',
}

export enum UsersActionTypeEnum {
  CREATE_SUCCESS = 'USERS_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS',
  DISABLE_SUCCESS = 'USERS_DISABLE_SUCCESS',
  ENABLE_SUCCESS = 'USERS_ENABLE_SUCCESS',
  READ_LIST_SUCCESS = 'USERS_READ_LIST_SUCCESS',
  RESET_API_STATE = 'USERS_RESET_API_STATE',
  READ_LIST_GROUP_SUCCESS = 'READ_LIST_GROUP_SUCCESS',
  READ_LIST_SYSTEM_SUCCESS = 'READ_LIST_SYSTEM_SUCCESS',
  READ_LIST_VESSEL_BY_TOKEN_SUCCESS = 'READ_LIST_VESSEL_BY_TOKEN_SUCCESS',
  READ_LIST_RANK_BY_TOKEN_SUCCESS = 'READ_LIST_RANK_BY_TOKEN_SUCCESS',
  READ_LIST_COMPANY_SUCCESS = 'READ_LIST_COMPANY_SUCCESS',
  DOWNLOAD_LIST_USER_SUCCESS = 'DOWNlOAD_LIST_USERS_SUCCESS',
}

export enum GroupsActionTypeEnum {
  CREATE_REQUEST = 'GROUPS_CREATE_REQUEST',
  CREATE_SUCCESS = 'GROUPS_CREATE_SUCCESS',
  CREATE_FAIL = 'GROUPS_CREATE_FAIL',
  ENABLE_REQUEST = 'GROUPS_DELETE_REQUEST',
  ENABLE_SUCCESS = 'GROUPS_DELETE_SUCCESS',
  ENABLE_FAIL = 'GROUPS_DELETE_FAIL',
  DISABLE_REQUEST = 'GROUPS_DELETE_REQUEST',
  DISABLE_SUCCESS = 'GROUPS_DELETE_SUCCESS',
  DISABLE_FAIL = 'GROUPS_DELETE_FAIL',
  READ_LIST_REQUEST = 'GROUPS_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'GROUPS_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'GROUPS_READ_LIST_FAIL',
  RESET_API_STATE = 'GROUPS_RESET_API_STATE',
}

export enum AnalyticsActionTypeEnum {
  CREATE_REQUEST = 'ANALYTICS_CREATE_REQUEST',
  CREATE_SUCCESS = 'ANALYTICS_CREATE_SUCCESS',
  CREATE_FAIL = 'ANALYTICS_CREATE_FAIL',
  READ_REQUEST = 'ANALYTICS_READ_REQUEST',
  READ_SUCCESS = 'ANALYTICS_READ_SUCCESS',
  READ_FAIL = 'ANALYTICS_READ_FAIL',
  UPDATE_REQUEST = 'ANALYTICS_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'ANALYTICS_UPDATE_SUCCESS',
  UPDATE_FAIL = 'ANALYTICS_UPDATE_FAIL',
  DELETE_REQUEST = 'ANALYTICS_DELETE_REQUEST',
  DELETE_SUCCESS = 'ANALYTICS_DELETE_SUCCESS',
  DELETE_FAIL = 'ANALYTICS_DELETE_FAIL',
  READ_LIST_SUCCESS = 'ANALYTICS_READ_LIST_SUCCESS',
  RESET_API_STATE = 'ANALYTICS_RESET_API_STATE',
}

export enum AsyncTaskTypeEnum {
  API_REQUEST = 'API_REQUEST',
  API_SUCCESS = 'API_SUCCESS',
  API_FAIL = 'API_FAIL',
  API_RESET = 'API_RESET',
}

export enum ReleaseNotesActionTypeEnum {
  READ_LIST_REQUEST = 'RELEASE_NOTES_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'RELEASE_NOTES_READ_LIST_SUCCESS',
  CREATE_SUCCESS = 'CREATE_RELEASE_NOTE_SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_RELEASE_NOTE_SUCCESS',
}
