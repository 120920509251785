import { TagActionTypes, TagReducerState } from '../../types/tagStore';
import { TagActionTypeEnum } from '../../enums/actions';

export const initialState: TagReducerState = {
  tagDetail: {
    id: '',
    rev: '',
    destination: '',
    start: '',
    end: '',
    supplierId: '',
    imoNo: '',
    destinationReference: '',
  },
  tagList: [],
};

export const tagReducer = (
  state = initialState,
  action: TagActionTypes,
): TagReducerState => {
  switch (action.type) {
    case TagActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        tagDetail: {
          ...state.tagDetail,
          ...action.payload,
        },
      };
    case TagActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        tagDetail: {
          ...state.tagDetail,
          ...action.payload,
        },
      };
    case TagActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        tagDetail: {
          ...state.tagDetail,
          ...action.payload,
        },
      };
    case TagActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
      };
    case TagActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        tagList: [...action.payload],
      };
    case TagActionTypeEnum.RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
