import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { resetSnackBarAction } from '../../store/actions/snackBarActions';
import { RootStateType } from '../../types/store';
import { styles } from './styles';

/**
 * SnackBar component
 * Render notification messages
 */
export default function SnackBar() {
  const classes = styles();
  const dispatch = useDispatch();
  const { show, message, messageType } = useSelector(
    (state: RootStateType) => state.snackBarReducer,
  );

  const handleClose = () => {
    dispatch(resetSnackBarAction());
  };

  if (show) {
    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={show}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={messageType}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
  return null;
}
