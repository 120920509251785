import { UsersActionTypes, UsersReducerState } from '../../types/usersStore';
import { UsersActionTypeEnum } from '../../enums/actions';

export const initialState: UsersReducerState = {
  usersList: [],
  userDetail: {
    belongsTo: '',
    company: '',
    canAccess: [],
    role: '',
    email: '',
    username: '',
    password: '',
    isEnabled: true,
  },
  groupList: [],
  systemList: [],
  vesselListByToken: [],
  rankList: [],
  companyList: [],
};

export const userReducer = (
  state = initialState,
  action: UsersActionTypes,
): UsersReducerState => {
  switch (action.type) {
    case UsersActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case UsersActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_GROUP_SUCCESS:
      return {
        ...state,
        groupList: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_SYSTEM_SUCCESS:
      return {
        ...state,
        systemList: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_VESSEL_BY_TOKEN_SUCCESS:
      return {
        ...state,
        vesselListByToken: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_RANK_BY_TOKEN_SUCCESS:
      return {
        ...state,
        rankList: action.payload,
      };
    case UsersActionTypeEnum.READ_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
      };
    case UsersActionTypeEnum.RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
