import { AuthActionTypeEnum } from '../../enums/actions';
import { AuthActionTypes, AuthReducerState } from '../../types/authStore';
import {
  API_FAIL_STATE,
  API_INITIAL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';

export const initialState: AuthReducerState = {
  logInState: API_INITIAL_STATE,
  error: null,
  accessToken: '',
  refreshToken: '',
};

export const authReducer = (state = initialState, action: AuthActionTypes) => {
  const { type, payload } = action;
  switch (type) {
    case AuthActionTypeEnum.LOG_IN_REQUEST:
      return {
        ...state,
        logInState: {
          ...API_REQUEST_STATE,
        },
      };
    case AuthActionTypeEnum.LOG_IN_SUCCESS:
      return {
        ...state,
        ...payload,
        logInState: {
          ...API_SUCCESS_STATE,
        },
      };
    case AuthActionTypeEnum.LOG_IN_FAIL:
      return {
        ...state,
        logInState: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case AuthActionTypeEnum.LOG_OUT_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
