import React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import { styles } from './styles';

/**
 * CircularLoader
 * Render circular loading spinner / loader
 */
interface ComponentProps {
  circularProgressProps?: CircularProgressProps;
}

export default function CircularLoader({
  circularProgressProps,
}: ComponentProps) {
  const classes = styles();

  return (
    <div className={classes.container} data-testid="circularLoader">
      <CircularProgress {...circularProgressProps} />
    </div>
  );
}
