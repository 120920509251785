import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { asyncTaskReducer } from './reducers/asyncTaskReducer';
import { authReducer } from './reducers/authReducer';
import { doorConfigReducer } from './reducers/doorConfigReducer';
import { snackBarReducer } from './reducers/snackBarReducer';
import { supplierReducer } from './reducers/supplierReducer';
import { tagReducer } from './reducers/tagReducer';
import { stockRecReducer } from './reducers/stockrecReducer';
import { shipOwnerReducer } from './reducers/shipOwnerReducer';
import { vesselReducer } from './reducers/vesselReducer';
import { vesselClassReducer } from './reducers/vesselClassReducer';
import { userReducer } from './reducers/userReducer';
import { groupsReducer } from './reducers/groupsReducer';
import { analyticsReducer } from './reducers/analyticsReducer';
import { releaseNotesReducer } from './reducers/releaseNotesReducer';
import { RootStateType } from '../types/store';

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export default createStore(
  combineReducers<RootStateType>({
    authReducer,
    doorConfigReducer,
    snackBarReducer,
    supplierReducer,
    tagReducer,
    stockRecReducer,
    shipOwnerReducer,
    vesselReducer,
    vesselClassReducer,
    userReducer,
    groupsReducer,
    analyticsReducer,
    asyncTaskReducer,
    releaseNotesReducer,
  }),
  composeEnhancers(applyMiddleware(thunk)),
);
