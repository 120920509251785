import { SnackBarType } from '../../types/snackBar';
import { SnackBarActionTypeEnum } from '../../enums/actions';

// Error can come from many places. We should avoid expect it's always ApiError
export function updateSnackBarAction(payload: SnackBarType, error?: any) {
  // Return API response error message if exists
  if (error?.data?.message || error?.message) {
    return {
      type: SnackBarActionTypeEnum.UPDATE,
      payload: {
        message: error?.data?.message ?? error.message,
        messageType: 'error',
      },
    };
  }
  return {
    type: SnackBarActionTypeEnum.UPDATE,
    payload,
  };
}

export function resetSnackBarAction() {
  return {
    type: SnackBarActionTypeEnum.RESET,
  };
}
