export enum SecureStorageEnum {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum CopyrightEnum {
  COPYRIGHT = 'Copyright © ',
}

export enum SparesEnum {
  SPARES_URL = 'https://www.sparescnx.com/',
}

export enum AuthErrorCode {
  FORBIDDEN = 403,
}
