import {
  ReleaseNotesActionTypes,
  ReleaseNotesReducerState,
} from 'types/releaseNotesStore';
import { ReleaseNotesActionTypeEnum } from 'enums/actions';
import { defaultParams } from 'utils/defaultValues/releaseNotes';
import uniqBy from 'lodash/uniqBy';

export const initialState: ReleaseNotesReducerState = {
  releaseNoteList: undefined,
  params: {
    system: defaultParams.system,
    recordsPerpage: defaultParams.recordsPerpage,
    sort: defaultParams.sort,
    sortField: defaultParams.sortField,
  },
};

export const releaseNotesReducer = (
  state = initialState,
  action: ReleaseNotesActionTypes,
): ReleaseNotesReducerState => {
  switch (action.type) {
    case ReleaseNotesActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
        releaseNoteList: action.payload?.resetDataTable
          ? undefined
          : state.releaseNoteList,
      };
    case ReleaseNotesActionTypeEnum.READ_LIST_SUCCESS: {
      // Concat response from API to current releaseNote list. Then remove duplicate releaseNote
      const concatReleaseNoteList = [
        ...(state.releaseNoteList?.data || []),
        ...action.payload.data,
      ];
      const uniqueReleaseNotes = uniqBy(concatReleaseNoteList, 'id');

      return {
        ...state,
        releaseNoteList: {
          ...action.payload,
          data: uniqueReleaseNotes,
        },
      };
    }

    default:
      return state;
  }
};
