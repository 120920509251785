import {
  DoorConfigActionTypes,
  DoorConfigReducerState,
} from '../../types/doorConfigStore';
import {
  API_FAIL_STATE,
  API_INITIAL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';
import { DoorConfigActionTypeEnum } from '../../enums/actions';

export const initialState: DoorConfigReducerState = {
  create: API_INITIAL_STATE,
  read: API_INITIAL_STATE,
  update: API_INITIAL_STATE,
  del: API_INITIAL_STATE,
  readList: API_INITIAL_STATE,
  error: null,
  id: '',
  rev: '',
  doors: [],
  imoNo: '',
  maxTimeout: '',
  doorConfigList: [],
};

export const doorConfigReducer = (
  state = initialState,
  action: DoorConfigActionTypes,
): DoorConfigReducerState => {
  const { type, payload } = action;
  switch (type) {
    case DoorConfigActionTypeEnum.CREATE_REQUEST:
      return {
        ...state,
        create: {
          ...API_REQUEST_STATE,
        },
      };
    case DoorConfigActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        ...payload,
        create: {
          ...API_SUCCESS_STATE,
        },
      };
    case DoorConfigActionTypeEnum.CREATE_FAIL:
      return {
        ...state,
        create: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case DoorConfigActionTypeEnum.READ_REQUEST:
      return {
        ...state,
        read: {
          ...API_REQUEST_STATE,
        },
      };
    case DoorConfigActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        ...payload,
        read: {
          ...API_SUCCESS_STATE,
        },
      };
    case DoorConfigActionTypeEnum.READ_FAIL:
      return {
        ...state,
        read: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case DoorConfigActionTypeEnum.UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...API_REQUEST_STATE,
        },
      };
    case DoorConfigActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        ...payload,
        update: {
          ...API_SUCCESS_STATE,
        },
      };
    case DoorConfigActionTypeEnum.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case DoorConfigActionTypeEnum.DELETE_REQUEST:
      return {
        ...state,
        del: {
          ...API_REQUEST_STATE,
        },
      };
    case DoorConfigActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
        del: {
          ...API_SUCCESS_STATE,
        },
      };
    case DoorConfigActionTypeEnum.DELETE_FAIL:
      return {
        ...state,
        del: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case DoorConfigActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        readList: {
          ...API_REQUEST_STATE,
        },
      };
    case DoorConfigActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        readList: {
          ...API_SUCCESS_STATE,
        },
        doorConfigList: [...payload],
      };
    case DoorConfigActionTypeEnum.READ_LIST_FAIL:
      return {
        ...state,
        readList: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case DoorConfigActionTypeEnum.RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
