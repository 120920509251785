import {
  ShipOwnerActionTypes,
  ShipOwnerReducerState,
} from '../../types/shipOwnerStore';
import { ShipOwnerActionTypeEnum } from '../../enums/actions';

export const initialState: ShipOwnerReducerState = {
  shipOwnerDetail: undefined,
  shipOwnerList: [],
  shipOwnerVesselClasses: [],
  shipOwnerERPConnection: {
    erpAccountList: [],
    erpServerList: [],
  },
  shipOwnerSettingsList: [],
};

export const shipOwnerReducer = (
  state = initialState,
  action: ShipOwnerActionTypes,
): ShipOwnerReducerState => {
  switch (action.type) {
    case ShipOwnerActionTypeEnum.SHIP_OWNER_CREATE_SUCCESS:
      return {
        ...state,
        shipOwnerDetail: {
          ...state.shipOwnerDetail,
          ...action.payload,
        },
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_SUCCESS:
      return {
        ...state,
        shipOwnerDetail: {
          ...state.shipOwnerDetail,
          ...action.payload,
        },
      };
    case ShipOwnerActionTypeEnum.SHIP_OWNER_UPDATE_SUCCESS:
      return {
        ...state,
        shipOwnerDetail: {
          ...state.shipOwnerDetail,
          ...action.payload,
        },
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_DELETE_SUCCESS:
      return {
        ...state,
      };
    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_LIST_SUCCESS:
      return {
        ...state,
        shipOwnerList: [...action.payload],
      };
    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_DETAIL_SUCCESS:
      return {
        ...state,
        shipOwnerDetail: action.payload,
      };
    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_VESSEL_CLASSES_SUCCESS:
      return {
        ...state,
        shipOwnerVesselClasses: action.payload,
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_DETAIL_RESET:
      return {
        ...state,
        shipOwnerDetail: undefined,
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_CONFIGURATION_SUCCESS:
      return {
        ...state,
        shipOwnerSettingsList: action.payload,
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_ERP_ACCOUNT_SUCCESS:
      return {
        ...state,
        shipOwnerERPConnection: {
          erpAccountList: action.payload,
          erpServerList: state?.shipOwnerERPConnection?.erpServerList ?? [],
        },
      };
    case ShipOwnerActionTypeEnum.SHIP_OWNER_READ_ERP_SEVER_SUCCESS:
      return {
        ...state,
        shipOwnerERPConnection: {
          erpAccountList: state?.shipOwnerERPConnection?.erpAccountList ?? [],
          erpServerList: action.payload,
        },
      };

    case ShipOwnerActionTypeEnum.SHIP_OWNER_RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
