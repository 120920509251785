import {
  AnalyticsActionTypes,
  AnalyticsReducerState,
} from '../../types/analyticsStore';
import { AnalyticsActionTypeEnum } from '../../enums/actions';

export const initialState: AnalyticsReducerState = {
  analyticsDetail: undefined,
  analyticsList: [],
};

export const analyticsReducer = (
  state = initialState,
  action: AnalyticsActionTypes,
): AnalyticsReducerState => {
  switch (action.type) {
    case AnalyticsActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        analyticsDetail: {
          ...state.analyticsDetail,
          ...action.payload,
        },
      };
    case AnalyticsActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        analyticsDetail: {
          ...state.analyticsDetail,
          ...action.payload,
        },
      };
    case AnalyticsActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
      };
    case AnalyticsActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        analyticsList: [...action.payload],
      };
    case AnalyticsActionTypeEnum.RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
