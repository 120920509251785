import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CircularLoader from '../../components/Loader/CircularLoader';
import SnackBar from '../../components/SnackBar';
import { RootStateType } from '../../types/store';
import secureStorageUtils from '../../utils/secureStorage';
import { SecureStorageEnum } from '../../enums/auth';

const AppRoutes = lazy(() => import('../../components/Routes/AppRoutes'));
const AuthRoutes = lazy(() => import('../../components/Routes/AuthRoutes'));

/**
 * App container
 * Check user's authentication
 * Render routes based on user's authentication
 */
export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { logInState, accessToken, refreshToken } = useSelector(
    (state: RootStateType) => state.authReducer,
  );

  useEffect(() => {
    // Set user's authentication state based on token
    const token = secureStorageUtils.getItem(SecureStorageEnum.ACCESS_TOKEN);
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    // Set tokens and user's authentication state after successful login
    if (logInState.apiSuccess) {
      secureStorageUtils.setItem(SecureStorageEnum.ACCESS_TOKEN, accessToken);
      secureStorageUtils.setItem(SecureStorageEnum.REFRESH_TOKEN, refreshToken);
      setIsAuthenticated(true);
    }
  }, [logInState.apiSuccess, accessToken, refreshToken]);

  return (
    <BrowserRouter>
      <Suspense fallback={<CircularLoader />}>
        {isAuthenticated ? <AppRoutes /> : <AuthRoutes />}
        <SnackBar />
      </Suspense>
    </BrowserRouter>
  );
}
