import {
  StockRecActionTypes,
  StockRecReducerState,
} from '../../types/stockrecStore';

import { StockRecActionTypeEnum } from '../../enums/actions';

export const initialState: StockRecReducerState = {
  stockRecList: [],
  stockRecDetail: {
    id: '',
    rev: '',
    startDate: '',
    endDate: '',
    vesselClasses: [],
    companyName: '',
    docType: '',
  },
  stockReconMap: {},
};

export const stockRecReducer = (
  state = initialState,
  action: StockRecActionTypes,
): StockRecReducerState => {
  switch (action.type) {
    case StockRecActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        stockRecDetail: {
          ...state.stockRecDetail,
          ...action.payload,
        },
      };
    case StockRecActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        stockRecDetail: {
          ...state.stockRecDetail,
          ...action.payload,
        },
      };
    case StockRecActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        stockRecDetail: {
          ...state.stockRecDetail,
          ...action.payload,
        },
      };
    case StockRecActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
      };
    case StockRecActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        stockRecList: [...action.payload],
      };
    case StockRecActionTypeEnum.READ_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        stockReconMap: {
          ...state.stockReconMap,
          [action.parentId]: action.payload?.filter(
            (child) => !child.isTraining,
          ),
        },
      };
    case StockRecActionTypeEnum.RESET_API_STATE:
      return initialState;
    default:
      return state;
  }
};
