import {
  SupplierActionTypes,
  SupplierReducerState,
} from '../../types/supplierStore';
import {
  API_FAIL_STATE,
  API_INITIAL_STATE,
  API_REQUEST_STATE,
  API_SUCCESS_STATE,
} from '../../utils/defaultValues/apiRequestState';
import { SupplierActionTypeEnum } from '../../enums/actions';

export const initialState: SupplierReducerState = {
  create: API_INITIAL_STATE,
  read: API_INITIAL_STATE,
  update: API_INITIAL_STATE,
  del: API_INITIAL_STATE,
  readList: API_INITIAL_STATE,
  error: null,
  id: '',
  rev: '',
  username: '',
  supplier: '',
  supplierId: '',
  password: '',
  confirmedPassword: '',
  supplierList: [],
};

export const supplierReducer = (
  state = initialState,
  action: SupplierActionTypes,
): SupplierReducerState => {
  const { type, payload } = action;
  switch (type) {
    case SupplierActionTypeEnum.CREATE_REQUEST:
      return {
        ...state,
        create: {
          ...API_REQUEST_STATE,
        },
      };
    case SupplierActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        ...payload,
        create: {
          ...API_SUCCESS_STATE,
        },
      };
    case SupplierActionTypeEnum.CREATE_FAIL:
      return {
        ...state,
        create: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case SupplierActionTypeEnum.READ_REQUEST:
      return {
        ...state,
        read: {
          ...API_REQUEST_STATE,
        },
      };
    case SupplierActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        ...payload,
        read: {
          ...API_SUCCESS_STATE,
        },
      };
    case SupplierActionTypeEnum.READ_FAIL:
      return {
        ...state,
        read: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case SupplierActionTypeEnum.UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...API_REQUEST_STATE,
        },
      };
    case SupplierActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        ...payload,
        update: {
          ...API_SUCCESS_STATE,
        },
      };
    case SupplierActionTypeEnum.UPDATE_FAIL:
      return {
        ...state,
        update: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case SupplierActionTypeEnum.DELETE_REQUEST:
      return {
        ...state,
        del: {
          ...API_REQUEST_STATE,
        },
      };
    case SupplierActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
        del: {
          ...API_SUCCESS_STATE,
        },
      };
    case SupplierActionTypeEnum.DELETE_FAIL:
      return {
        ...state,
        del: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case SupplierActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        readList: {
          ...API_REQUEST_STATE,
        },
      };
    case SupplierActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        readList: {
          ...API_SUCCESS_STATE,
        },
        supplierList: [...payload],
      };
    case SupplierActionTypeEnum.READ_LIST_FAIL:
      return {
        ...state,
        readList: {
          ...API_FAIL_STATE,
        },
        error: payload,
      };
    case SupplierActionTypeEnum.RESET_API_STATE:
      return {
        ...state,
        create: { ...API_INITIAL_STATE },
        read: { ...API_INITIAL_STATE },
        update: { ...API_INITIAL_STATE },
        del: { ...API_INITIAL_STATE },
        readList: { ...API_INITIAL_STATE },
      };
    default:
      return state;
  }
};
