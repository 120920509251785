import { API_INITIAL_STATE } from 'utils/defaultValues/apiRequestState';
import {
  API_FAIL_STATE,
  API_SUCCESS_STATE,
  API_REQUEST_STATE,
} from '../../utils/defaultValues/apiRequestState';

import { AsyncTaskTypeEnum } from '../../enums/actions';
import {
  AsyncTaskReducerState,
  AsyncTaskActionTypes,
} from '../../types/asyncTaskStore';

export const initialState: AsyncTaskReducerState = {};

export const asyncTaskReducer = (
  state = initialState,
  action: AsyncTaskActionTypes,
) => {
  switch (action.type) {
    case AsyncTaskTypeEnum.API_REQUEST: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          apiState: API_REQUEST_STATE,
        },
      };
    }
    case AsyncTaskTypeEnum.API_SUCCESS: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          apiState: API_SUCCESS_STATE,
        },
      };
    }
    case AsyncTaskTypeEnum.API_FAIL: {
      return {
        ...state,
        [action.meta]: {
          ...state[action.meta],
          apiState: API_FAIL_STATE,
          error: action.payload,
        },
      };
    }
    case AsyncTaskTypeEnum.API_RESET: {
      return {
        ...state,
        [action.payload]: {
          apiState: API_INITIAL_STATE,
        },
      };
    }
    default:
      return state;
  }
};
