import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeColors } from '../types/style';

// Module augmentation for Typescript to support the following custom properties
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: ThemeColors;
  }
  interface ThemeOptions {
    colors: ThemeColors;
  }
}

const defaultTheme = createMuiTheme();

// For new colors, add object key to 'IColors' interface
const colors = {
  navyBlue: '#142D58',
  brightBlue: '#2D7DFE',
  lightBlue: '#E3E8EC',
  fieryRed: '#FF003F',
  happyGreen: '#03BD83',
  longingOrange: '#FFA401',
  darkBlack: '#222222',
  gray1: '#666666',
  gray2: '#999999',
  gray3: '#DDDDDD',
  gray4: '#F5F5F5',
  pureWhite: '#FFFFFF',
  // Not in style guide
  hoverBlue: '#00ACAD',
};

const defaultFontFamily = 'Heebo, Roboto, Arial, sans-serif';

/**
 * DEFAULT_THEME_CONFIG
 * Set global and re-usable style for project
 * Set default style for all MUI components
 * Allow overriding MUI default styles and props
 */
const DEFAULT_THEME_CONFIG = {
  colors,
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 720,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: colors.navyBlue,
    },
    secondary: {
      main: colors.brightBlue,
    },
    error: {
      main: colors.fieryRed,
    },
    warning: {
      main: colors.longingOrange,
    },
    info: {
      main: colors.brightBlue,
    },
    success: {
      main: colors.happyGreen,
    },
    text: {
      disabled: colors.gray2,
      hint: colors.gray1,
      primary: colors.darkBlack,
      secondary: colors.darkBlack,
    },
  },
  spacing: 1,
  typography: {
    fontFamily: defaultFontFamily,
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    h1: {
      fontFamily: defaultFontFamily,
      fontSize: '6rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1.167,
    },
    h2: {
      fontFamily: defaultFontFamily,
      fontSize: '3.75rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: defaultFontFamily,
      fontSize: '3rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.167,
    },
    h4: {
      fontFamily: defaultFontFamily,
      fontSize: '2.125rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.235,
    },
    h5: {
      fontFamily: defaultFontFamily,
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.334,
    },
    h6: {
      fontFamily: defaultFontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily: defaultFontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily: defaultFontFamily,
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.57,
    },
    body1: {
      fontFamily: defaultFontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: defaultFontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.43,
    },
    button: {
      fontFamily: defaultFontFamily,
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75,
    },
    caption: {
      fontFamily: defaultFontFamily,
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.66,
    },
    overline: {
      fontFamily: defaultFontFamily,
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 2.66,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  // Override MUI components' default style
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 12,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenu: {
      list: {
        maxHeight: '300px',
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
        whiteSpace: 'pre-wrap' as const,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 16,
        paddingRight: 16,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
    MuiDialogActions: {
      root: {
        padding: defaultTheme.spacing(2, 3),
      },
      spacing: {
        '&>:not(:first-child)': {
          marginLeft: defaultTheme.spacing(2),
        },
      },
    },
    MUIDataTableSearch: {
      searchText: {
        height: 'auto',
      },
    },
  },
};

export const theme = createMuiTheme(DEFAULT_THEME_CONFIG);
