import { VesselActionTypes, VesselReducerState } from '../../types/vesselStore';
import { VesselActionTypeEnum } from '../../enums/actions';

export const initialState: VesselReducerState = {
  vesselDetail: {
    id: '',
    rev: '',
    vesselName: '',
    imoNo: '',
    companyName: '',
    vesselClass: '',
    vesselDefaultEmail: '',
    scID: undefined,
  },
  vesselList: [],
  shipOwnerVessels: [],
  writebackData: {},
  configList: [],
};

export const vesselReducer = (
  state = initialState,
  action: VesselActionTypes,
): VesselReducerState => {
  switch (action.type) {
    case VesselActionTypeEnum.GET_TRANSFORMATION_DONE:
      return {
        ...state,
        writebackData: {
          ...state.writebackData,
          ...action.payload,
        },
      };
    case VesselActionTypeEnum.SHIP_OWNER_READ_VESSELS_SUCCESS:
      return {
        ...state,
        shipOwnerVessels: action.payload?.concat(),
      };
    case VesselActionTypeEnum.CREATE_SUCCESS:
      return {
        ...state,
        vesselDetail: action.payload,
      };
    case VesselActionTypeEnum.READ_SUCCESS:
      return {
        ...state,
        vesselDetail: action.payload,
      };
    case VesselActionTypeEnum.UPDATE_SUCCESS:
      return {
        ...state,
        vesselDetail: action.payload,
      };
    case VesselActionTypeEnum.DELETE_SUCCESS:
      return {
        ...state,
      };
    case VesselActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        vesselList: [...action.payload],
      };
    case VesselActionTypeEnum.RESET_API_STATE:
      return initialState;
    case VesselActionTypeEnum.READ_LIST_CONFIG_VESSEL_SETTING_SUCCESS:
      return {
        ...state,
        configList: [...action.payload],
      };
    default:
      return state;
  }
};
