import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));
